import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Slider from "../components/slider";
import Minislider from "../components/minislider";
import store from "../images/svg/store.svg";
import play from "../images/svg/play.svg";
import Svgsprite from "../components/svgsprite";
import Youtube from "../components/youtube";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Slider />
    <section data-side="about" className="contentbox wrap --fullw">
      <div className="textcontent">
        <h2>
          About boatpilot <span>company</span>
        </h2>
        <p>
          BoatPilot was founded in 2012, when a group of yachting enthusiasts
          decided that the existing marine navigation solutions did not follow
          the market's demand. Having started with creating an open and
          affordable mobile navigation app, the company has turned into an
          internationally acclaimed navigation provider, integrator and
          navigation hardware manufacturer in just six years.
        </p>
        <p>
          BoatPilot makes anything from software, to marine electronics and
          complex charter fleet monitoring and management solutionsr
        </p>
        <h3>Boatpilot platforms</h3>
        <p>
          The platforms developed by BoatPilot for collecting and analyzing
          geo-data, which include chartplotter integrated advertising
          possibilities for businesses, don't have analogs anywhere in the
          world. We work closely with the largest yachting community in the
          world to receive the latest information on marine situations, and
          integrate the latest technology solutions into the navigation market.
        </p>
      </div>
      <div className="minislider">
        <Minislider
          names={[
            "about_1.jpg",
            "about_2.jpg",
            "about_3.jpg",
            "about_4.jpg",
            "about_5.jpg",
          ]}
        />
      </div>
      <div className="videocontent">
        <Youtube tubeid="VKf7A2tD4IM" posterimagename="tubeposter_about.jpg" />
      </div>
    </section>
    <section data-side="module" className="contentbox wrap --fullw --dark">
      <div className="textcontent">
        <h2>
          Boatgod <span>hardware module</span>
        </h2>
        <p>
          BoatGod is the basis of the marine electronics program from BoatPilot.
          Two options of the hardware are released: for private yachtsmen and
          for charter companies. The modules let the user work with all tools
          connected to the NMEA network right from the chartplotter.
        </p>

        <p>
          There’s also a possibility to install additional sensors that will
          improve the convenience and safety of operating the vessel. BoatGod
          modules are easy to install and don’t require any special knowledge.
        </p>

        <p>
          Especially for charter companies, we’ve created a solution based on
          the Haas/Saas model. In this case, installing the BoatGod module will
          raise the fleet management efficiency and minimize losses by 30%
          through damage prevention and timely diagnosis, additional cooperation
          with the skipper, improved statistical and analytical module, and
          highly efficient system monitoring that ranges from basic equipment to
          the vessel’s engine.
        </p>
      </div>
      <div className="minislider">
        <Minislider
          names={[
            "god_1.jpg",
            "god_2.jpg",
            "god_3.jpg",
            "god_4.jpg",
            "god_5.jpg",
            "god_6.jpg",
            "god_7.jpg",
          ]}
        />
      </div>
      <div className="videocontent">
        <Youtube tubeid="vmlTH5y4uDQ" posterimagename="tubeposter_god.jpg" />
      </div>
    </section>
    <section data-side="soft" className="contentbox wrap --fullw">
      <div className="textcontent">
        <h2>
          Chartplotter <span>powered by boatpilot</span>
        </h2>
        <p>
          The BoatPilot marine navigation app (chartplotter) is a software
          solution oriented towards the private and charter fleets with yachts
          from 7 to 30 meters long. The chartplotter is based on BoatPilot's
          open platform, which grows and improves with the help of the
          community.
        </p>

        <p>
          Active skippers and yachting amateurs contribute by adding thousands
          of new points and corrections to the charts, as such creating the most
          complete and up-to-date marine navigation product on the market.
          Additionally, BoatPilot has free charts of European waterways, and is
          currently working to include the North American territory. Our own
          hardware — BoatGod module — allows us to make significant
          clarifications into charts by receiving depth data from vessel sonars.
          We can definitely say that BoatPilot has the most complete
          geo-database in the world, with the highest speed of depth information
          update.
        </p>

        <p>
          Download BoatPilot today and become a part of the largest yachting
          community that is changing the world of marine navigation!{" "}
        </p>
        <p>
          <a style={{ marginRight: `1em` }} href="/">
            <Svgsprite
              svgclass="storebtn"
              name={store.id}
              vbox={store.viewBox}
            />
          </a>
          <a href="/">
            <Svgsprite svgclass="storebtn" name={play.id} vbox={play.viewBox} />
          </a>
        </p>
      </div>
      <div className="minislider">
        <Minislider
          names={[
            "plotter_1.jpg",
            "plotter_2.jpg",
            "plotter_3.jpg",
            "plotter_4.jpg",
            "plotter_5.jpg",
            "plotter_6.jpg",
            "plotter_7.jpg",
            "plotter_8.jpg",
            "plotter_9.jpg",
            "plotter_10.jpg",
            "plotter_11.jpg",
            "plotter_12.jpg",
            "plotter_13.jpg",
          ]}
        />
      </div>
      <div className="videocontent">
        <Youtube
          tubeid="lsEGtPeWJ-w"
          posterimagename="tubeposter_chartplotter.jpg"
        />
      </div>
    </section>
    <section data-side="b2b" className="contentbox wrap --fullw --dark">
      <div className="textcontent">
        <h2>
          B2B HaaS <span>and advertising platform</span>
        </h2>
        <p>
          BoatPilot supports two B2B directions: a chartplotter integrated
          advertising platform, and charter fleet monitoring and management
          system.
        </p>

        <p>
          If you are interested in promoting your business through chartplotter
          advertisement, please download our media brief HERE.
        </p>

        <p>
          If you represent a charter company and would like to raise the
          efficiency of your fleet management systems, lower your maintenance
          expenditures and make more money by monitoring your entire fleet using
          the BoatGod module, please follow this link.
        </p>
      </div>
      <div className="minislider">{/* <Minislider /> */}</div>
      <div className="videocontent">
        <Youtube tubeid="dEttg0FC4ug" posterimagename="tubeposter_b2b.jpg" />
      </div>
    </section>
    <section data-side="r&d" className="contentbox wrap --fullw">
      <div className="textcontent">
        <h2>
          Promising developments <span>and research</span>
        </h2>
        <p>
          BoatPilot is the first company in the world that has introduced a
          navigation solution with augmented reality back in 2017.
        </p>

        <p>
          In 2019, we plan to start supplying the commercial version of the AR
          systems we created. The system data that works within the BoatPilot
          ecosystem together with the BoatGod hardware and the chartplotter app
          has proven itself to be a highly efficient skipper companion during
          navigation in difficult weather conditions and complicated waterways,
          especially at night.We believe that a wide use of our AR system will
          lead to minimizing navigation related risks and will raise the safety
          of our waterways.
        </p>

        <p>
          If you are interested in learning more about our AR solutions, please{" "}
          <Link to="/contacts">contact us</Link>.
        </p>
      </div>
      <div className="videocontent">
        <Youtube
          tubeid="VvCNTJEi4X8"
          posterimagename="tubeposter_promises.jpg"
        />
      </div>
    </section>
  </Layout>
);

export default IndexPage;
