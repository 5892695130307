import React from "react";
import styles from "../css/slider.module.scss";
// import Swiper core and required components
import SwiperCore, { Navigation, Pagination, Autoplay, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
// install Swiper components
SwiperCore.use([Navigation, Pagination, A11y, Autoplay]);

const Image = (props) => {
  const {
    filename,
    type = "default",
    alt,
    sizes = "(max-width: 400px) 100vw, (max-width: 600px) 100vw,(max-width: 2000px) 100vw, (max-width: 4000px) 100vw",
  } = props;

  const images = useStaticQuery(graphql`
    query Indexslider {
      data: allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "indexslider" }
        }
      ) {
        edges {
          node {
            relativePath
            default: childImageSharp {
              fluid(quality: 85) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  const image = images.data.edges.find((n) => {
    return n.node.relativePath.includes(filename);
  });

  if (!image) {
    return null;
  }

  return (
    <Img
      alt={alt}
      fluid={{
        ...image.node[type].fluid,
        sizes: sizes,
      }}
    />
  );
};

const Slider = () => (
  <div className="sliderbox">
    <Swiper
      spaceBetween={0}
      slidesPerView={1}
      direction={"vertical"}
      navigation
      autoplay
      loop
      pagination={{ clickable: true }}
      //onSwiper={(swiper) => console.log(swiper)}
      //onSlideChange={() => console.log("slide change")}
    >
      <SwiperSlide className={styles.slidebody}>
        <Image alt="Fleet monitoring system" filename="slide_1.png" />
        <div className={`wrap ${styles.slidewrap}`}>
          Fleet monitoring system
        </div>
      </SwiperSlide>
      <SwiperSlide className={styles.slidebody}>
        <Image alt="Yachting community" filename="slide_2.jpg" />
        <div className={`wrap ${styles.slidewrap}`}>
          Boatgod module and sensors
        </div>
      </SwiperSlide>
      <SwiperSlide className={styles.slidebody}>
        <Image alt=" Works on real boats" filename="slide_3.jpg" />
        <div className={`wrap ${styles.slidewrap}`}>Community driven</div>
      </SwiperSlide>
      <SwiperSlide className={styles.slidebody}>
        <Image alt="Free charts by community" filename="slide_4.jpg" />
        <div className={`wrap ${styles.slidewrap}`}>
          Free charts by community
        </div>
      </SwiperSlide>
      <SwiperSlide className={styles.slidebody}>
        <Image alt="Boatpilot chartplotter apps" filename="slide_5.jpg" />
        <div className={`wrap ${styles.slidewrap}`}>
          Boatpilot chartplotter apps
        </div>
      </SwiperSlide>
      <SwiperSlide className={styles.slidebody}>
        <Image alt="Make yacht smart" filename="slide_6.jpg" />
        <div className={`wrap ${styles.slidewrap}`}>
          Smart boat/Connected boat
        </div>
      </SwiperSlide>
    </Swiper>
  </div>
);

export default Slider;
